@use '@angular/material' as mat;
@include mat.core();

$wolfMobile-primary: mat.define-palette(mat.$indigo-palette);
$wolfMobile-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$wolfMobile-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$wolfMobile-theme: mat.define-light-theme((color: (primary: $wolfMobile-primary,
        accent: $wolfMobile-accent,
        warn: $wolfMobile-warn,
      )));

@include mat.all-component-themes($wolfMobile-theme);

@font-face {
  font-family: "Roboto Condensed";
  src: url(assets/fonts/RobotoCondensed-Regular.ttf) format('truetype');
}

body,
html {
  font-weight: 400;
  font-style: normal;
  position: relative;
  height: 100%;
  max-height: 100%;
  max-width: 100%;
  bottom: 0;
  top: 0;
  left: 0;
  font-family: "Roboto Condensed", Roboto, Arial, sans-serif !important;
  line-height: 1.3;
  color: #005B33;
  background-color: #fff;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  overflow: hidden;
  margin: 0;
}

html {
  font-size: 18px;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
}